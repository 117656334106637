import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "eftpos-transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eftpos-transactions",
        "aria-label": "eftpos transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`EFTPOS transactions`}</h2>
    <p>{`Four EFTPOS transaction types are supported on machines:`}</p>
    <ul>
      <li parentName="ul">{`Purchases`}</li>
      <li parentName="ul">{`Refunds`}</li>
      <li parentName="ul">{`Reporting`}</li>
      <li parentName="ul">{`Reconciliation and Settlement`}</li>
    </ul>
    <p>{`However, the Transaction SDK currently only supports integrated Purchase transactions with associated response data. Other functions can be accessed directly on the machine or via the Tyro Health provider web portal.`}</p>
    <p><strong parentName="p">{`For all transactions`}</strong></p>
    <p>{`To process EFTPOS transactions, set:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`platform`}</strong>{` attribute to `}<strong parentName="li">{`funder`}</strong></li>
    </ul>
    <p>{`EFTPOS transactions are run under a merchant identifier or `}<strong parentName="p">{`MID`}</strong>{` which controls:`}</p>
    <ul>
      <li parentName="ul">{`The name of the business as presented on cardholder statements`}</li>
      <li parentName="ul">{`Settlement bank details - directing payments for approved transactions`}</li>
      <li parentName="ul">{`Invoicing from Tyro for any EFTPOS machine rental and merchant service fees`}</li>
    </ul>
    <p>{`A single EFTPOS machine can support multiple MIDs. This arrangement may be important for healthcare practice locations which support many independent providers but share a single physical EFTPOS machine. These MIDs can be associated with a single business and API key in the context of Tyro Health and dynamically changed at each transaction.`}</p>
    <p>{`MIDs can be associated with a `}<strong parentName="p">{`providerNumber`}</strong>{` so that a EFTPOS transaction sent with a `}<strong parentName="p">{`providerNumber`}</strong>{` will also set the default MID.`}</p>
    <p>{`Although multiple payment card processing methods are supported, set `}<strong parentName="p">{`payment-method`}</strong>{` to `}<strong parentName="p">{`terminal`}</strong>{` for EFTPOS machines.`}</p>
    <h3 {...{
      "id": "purchases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#purchases",
        "aria-label": "purchases permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Purchases`}</h3>
    <p>{`The process to complete a EFTPOS purchase transaction is as follows:`}</p>
    <NumberLabel number="1" mdxType="NumberLabel"> Set a chargeAmount in the PMS and send it to SDK, along with other optional details.</NumberLabel>
    <NumberLabel number="2" mdxType="NumberLabel"> Cardholders tap, swipe or insert their payment card on the machine.</NumberLabel>
    <NumberLabel number="3" mdxType="NumberLabel"> If the card has multiple linked payment types - such as Credit, CHQ, SAV - the cardholder will be presented an option to select one for the transaction.</NumberLabel>
    <NumberLabel number="4" mdxType="NumberLabel"> A transaction result is returned in real-time to the PMS and the machine prints a payment receipt.</NumberLabel>
    <p>{`The `}<strong parentName="p">{`chargeAmount`}</strong>{` is the total amount (note surcharges) to be debited on a payment card. This amount is the aggregated total, including any GST component. Line item details such as items are not required.`}</p>
    <p><strong parentName="p">{`Surcharging`}</strong></p>
    <p>{`Tyro EFTPOS machines support payment processing surcharges to be passed on to the cardholder. The surcharge is to cover merchant service fees and can be dynamically set depending on card type presented, e.g., a Platinum Visa credit card will have higher fees than a Visa debit card.`}</p>
    <p>{`Surcharging can be optionally enabled on the terminal. However, this setting cannot be configured via the Transaction SDK. When surcharging is enabled, the terminal will dynamically add a surcharge amount to each transaction. This adjusted amount will be paid to the merchant but is not currently returned in the completion message and may result in a reconciliation mis-match or a total that is not aligned with the PMS invoice amount.`}</p>
    <h3 {...{
      "id": "refunds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#refunds",
        "aria-label": "refunds permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Refunds`}</h3>
    <p>{`Integrated refunds are not currently supported via the SDK. Refunds can be issued via the Medipass portal and actioned on a terminal.`}</p>
    <p>{`Refunds can be issued for payment transactions at any point as long as the payment card has not expired and the amount is not above the original charge amount. Refunds usually require an administrator PIN to be entered on the terminal prior to completion. This administrator PIN should be known by the business administrator.`}</p>
    <h3 {...{
      "id": "reporting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reporting",
        "aria-label": "reporting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting`}</h3>
    <p>{`Integrated reporting is not currently supported via the SDK.`}</p>
    <h3 {...{
      "id": "reconciliation-and-settlement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#reconciliation-and-settlement",
        "aria-label": "reconciliation and settlement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reconciliation and settlement`}</h3>
    <p>{`End of day reconciliation and settlement reports can be manually generated on the terminal. This is a printed list of transactions processed for the settlement period.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      